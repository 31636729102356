.bodyText_b3nzzn9.MuiTypography-body1{text-align:center;color:#524658;font-weight:400;margin:auto;margin-bottom:32px;}.bodyText_b3nzzn9.MuiTypography-body1 p{margin:0;}
.section_sawy64{padding:0px 16px;}
.title_t1vs25q0{color:#2E0F4F;text-align:center;}.title_t1vs25q0.MuiTypography-root{margin-bottom:32px;}@media (min-width:960px){.title_t1vs25q0.MuiTypography-root{margin-bottom:32px;}}
.outerCard_ob28i5b{box-sizing:content-box;border-radius:20px;border:1px solid #C8C8C8;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.outerCard_ob28i5b p{margin:0;}.outerCard_ob28i5b.MuiGrid-root{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.outerCard_ob28i5b.MuiGrid-root:first-child{margin-top:0;}.outerCard_ob28i5b.MuiGrid-item{padding:8px 16px;}
.innerCard_i1dxcb8m{min-height:100%;box-shadow:0px 3px 40px #00000014;text-align:center;border-radius:20px;overflow:hidden;max-width:368px;}.innerCard_i1dxcb8m.MuiGrid-root{-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.imageContainer_i3mjbm8{margin:0 auto;width:100%;height:auto;background-color:white;position:relative;overflow:hidden;position:relative;}.imageContainer_i3mjbm8 img{width:100%;height:100%;object-fit:cover;}
.cardCenter_cdu7dor.MuiGrid-root{-webkit-box-pack:center !important;-webkit-justify-content:center !important;-ms-flex-pack:center !important;justify-content:center !important;}
.cardClick_cpvnp3c{cursor:pointer;}
.noButton_n1gjvu1g.MuiTypography-root{padding-bottom:32px;}
.availability_al1xjrn{border-radius:20px;color:white;position:absolute;top:18px;right:15px;padding:8px 16px;text-transform:uppercase;}
.bgPrimary_bqs3a8t{background-color:#2E0F4F;}
.bgSecondary_b4gs3tw{background-color:#17AB33;}
.loadMoreBtn_l1fkmant{outline:none;border:none;background-color:transparent;cursor:pointer;}
.loadMoreContainer_l1k3brmk{margin-top:40px;}
.iconPadding_i1wke2g7{padding-top:2px;}
.loadMoreBtnText_lqdchou{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media (min-width:960px){.loadMoreBtnText_lqdchou:hover{-webkit-text-decoration:underline;text-decoration:underline;}}
