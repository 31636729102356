.bodyText_b1l3ysdc.MuiTypography-body1{text-align:left;color:#524658;font-weight:400;margin:auto;margin-bottom:32px;}.bodyText_b1l3ysdc.MuiTypography-body1 p{margin:0;}
.section_sddvy9r{padding:0px 16px;}
.title_t1kaacq7{color:#2E0F4F;text-align:left;}.title_t1kaacq7.MuiTypography-root{margin-bottom:32px;}@media (min-width:960px){.title_t1kaacq7.MuiTypography-root{margin-bottom:32px;}}
.outerCard_o1q4yzcm{box-sizing:content-box;border-radius:20px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:white;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.outerCard_o1q4yzcm p{margin:0;}.outerCard_o1q4yzcm.MuiGrid-root{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.outerCard_o1q4yzcm.MuiGrid-root:first-child{margin-top:0;}.outerCard_o1q4yzcm.MuiGrid-item{padding:8px 16px;}
.innerCard_i15zkhe6{min-height:100%;box-shadow:0px 3px 40px #00000014;text-align:left;border-radius:20px;overflow:hidden;max-width:368px;}.innerCard_i15zkhe6.MuiGrid-root{-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.imageContainer_ioz86i6{margin:0 auto;width:100%;height:224px !important;background-color:white;position:relative;overflow:hidden;}.imageContainer_ioz86i6 img{width:100%;height:224px !important;border-radius:20px;object-fit:cover;object-position:center;}.imageContainer_ioz86i6 div{height:224px !important;}
.cardCenter_c14z54md.MuiGrid-root{-webkit-box-pack:center !important;-webkit-justify-content:center !important;-ms-flex-pack:center !important;justify-content:center !important;}
.cardClick_cm12vu{cursor:pointer;}
.noButton_n1j0h54y.MuiTypography-root{padding-bottom:32px;}
.availability_ar36ocj{border-radius:20px;color:white;position:absolute;top:18px;right:15px;padding:8px 16px;text-transform:uppercase;}
.bgPrimary_bqb9cr5{background-color:#2E0F4F;}
.bgSecondary_bu2ywv{background-color:#17AB33;}
.iconPadding_i1g3tbj2{padding-top:2px;}
.readMoreLink_r1xej2ac{color:#17AB33;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer;font-weight:700 !important;}
