.section_s1lr8fyv{text-align:center;background:#F8F8F8;}
.logo_ldoxudb{width:auto;}@media (min-width:600px){.logo_ldoxudb{height:auto;max-width:100%;}}@media (max-width:959.95px){.logo_ldoxudb{height:38px;max-width:240px;}}
.parent_p6c3mf6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}.parent_p6c3mf6.MuiGrid-container{border-radius:20px;margin:0 auto;height:inherit;background:#F8F8F8;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.parent_p6c3mf6 picture{display:block;}
.infoContainer_i1ntald7{padding:8px 0px 32px;}@media (min-width:960px){.infoContainer_i1ntald7{padding:56px 96px 56px 0px;text-align:left;}}
.caption_cvnktpy{color:#2E0F4F;text-transform:uppercase;padding-bottom:8px;}
.title_t12dsbxq{color:#2E0F4F;}@media (min-width:960px){.title_t12dsbxq.MuiTypography-root{font-size:2.813rem;}}.title_t12dsbxq div{font-size:2.188rem;margin-top:12px;}
.subtitle_si8rxzz{padding:16px 0px 24px;color:#524658;}.subtitle_si8rxzz.MuiTypography-root{font-weight:400;}
@media (max-width:959.95px){.trustpilotWrapper_t1qrqbiv iframe{margin-left:auto;margin-right:auto;}}
.mediaContainer_mdw22o0{padding:24px 0px 8px;border-radius:20px;}.mediaContainer_mdw22o0 img,.mediaContainer_mdw22o0 video{max-width:100%;}.mediaContainer_mdw22o0 img{height:100%;max-height:480px;object-fit:contain;}@media (min-width:960px){.mediaContainer_mdw22o0 img{max-width:100%;}}@media (min-width:960px) and (max-width:1279.95px){.mediaContainer_mdw22o0 img{height:360px;}}@media (min-width:600px) and (max-width:959.95px){.mediaContainer_mdw22o0 img{height:300px;}}@media (max-width:599.95px){.mediaContainer_mdw22o0 img{height:200px;}}@media (min-width:960px){.mediaContainer_mdw22o0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:72px 0px 64px;}.mediaContainer_mdw22o0 img,.mediaContainer_mdw22o0 video{max-height:480px;}}
.primaryButton_p13au40w.MuiButtonBase-root{margin:0px 0px 16px;}@media (min-width:960px){.primaryButton_p13au40w.MuiButtonBase-root{margin:8px 0px 24px;}}.primaryButton_p13au40w.MuiButtonBase-root .MuiButton-label img{margin-left:11px;position:relative;top:3px;-webkit-animation:bounce-icon-primaryButton_p13au40w .4s infinite alternate;animation:bounce-icon-primaryButton_p13au40w .4s infinite alternate;}@-webkit-keyframes bounce-icon-primaryButton_p13au40w{from{-webkit-transform:translateY(2px);-ms-transform:translateY(2px);transform:translateY(2px);}to{-webkit-transform:translateY(-5px);-ms-transform:translateY(-5px);transform:translateY(-5px);}}@keyframes bounce-icon-primaryButton_p13au40w{from{-webkit-transform:translateY(2px);-ms-transform:translateY(2px);transform:translateY(2px);}to{-webkit-transform:translateY(-5px);-ms-transform:translateY(-5px);transform:translateY(-5px);}}
